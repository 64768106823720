import React from 'react';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import BannerImage from '../svg/BannerImage';
import SvgCharts from '../svg/SvgCharts';
import BoardImage from '../svg/boardImage';
import HelpImage from '../svg/helpImage';

const Index = () => (
  <Layout>
    <div className="">
      <BannerImage />
    </div>
    <section id="about" className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-3xl xl:text-3xl font-bold leading-none">
            Consulting & Outsourcing
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            We offer team of experts working on cutting-edge technology to tailor custom software
            design and development on client ideas from concept to completion.
          </p>
          <p className="mt-4 text-primary font-bold">Transform business with technology</p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="services" className="py-20 lg:pb-40 lg:pt-18">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-3xl font-semibold">Core Services</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-2 px-4 pt-10 pb-6">
              <p className="font-semibold text-xl">Software Development</p>
              <div className="mt-4">
                <p>
                  Develop complex software applications for SME to Enterprise level clients around
                  the world. Along with DevOps support, cloud operations and monitoring.
                </p>
                <p className="font-semibold text-primary mt-2">Technologies </p>
                <p className="mt-2">Database - Oracle, MS-SQL, PostGresql, Sybase</p>
              </div>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-2 px-4 pt-10 pb-4">
              <p className="font-semibold text-xl">Web/Mobile Development</p>
              <div className="mt-4">
                <p>Build top-notch web/mobile applications</p>
                <p className="font-semibold text-primary mt-2">Technologies </p>
                <p>Web - Node, Python, Ruby on Rails, Java, Go, ReactJS, Vue, NextJS, .NET,</p>
                <p className="mt-4">Mobile - React Native, Flutter, Kotlin & Swift</p>
              </div>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-2 px-4 pt-10 pb-8">
              <p className="font-semibold text-xl">Network & Cybersecurity</p>
              <div className="mt-4">
                <p>
                  Empowering you with innovative technologies and emerging trends with our network,
                  cloud & cybersecurity expertise
                </p>
                <p className="font-semibold text-primary mt-2">Technologies </p>
                <p>SOC, VAPT, NOC operations </p>
              </div>
            </Card>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-8">
          <div className="flex-1 px-2">
            <Card className="mb-2 px-4 pt-6 pb-4">
              <p className="font-semibold text-xl mt-4">QA</p>
              <p>
                Focus on other important aspects of your project and leave QA to our team of QA
                engineers.
              </p>
              <p className="font-semibold mt-4 text-primary">Technologies </p>
              <p>Selenium, Appium, Cypress, JMeter, Jenkins, Zephyr, Postman</p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-2 pb-2">
              <p className="font-semibold text-xl">UI/UX Design</p>
              <p className="mt-2">
                Craft intuitive user interfaces and engaging user experiences tailored to your
                unique brand.
              </p>
              <p className="font-semibold  text-primary mt-2">Technologies </p>
              <p>Wireframing & Prototyping, Figma, Canva</p>
            </Card>
          </div>
          <div className="flex-1 px-2">
            {/* <Card className="px-4 py-10">
              <p className="font-semibold text-xl">Mobile app development</p>
              <p className="mt-4">
                We create beautiful Hybrid & Native Mobile Applications for iOS & Android
              </p>
              <p className="font-semibold mt-4 text-primary">Technologies </p>
              <p>React Native, Flutter, Kotlin & Swift</p>
            </Card> */}
            {/* <Card className="px-4  pb-4">
              <p className="font-semibold text-xl">Customer Support</p>
              <p>
                Specializing in handling customer interactions across various channels, our team
                ensures seamless support & enhance customer loyalty.
              </p>
              <p className="font-semibold mt-2 text-primary">Technologies</p>
              <p>Zendesk, Hubspot, Salesforce</p>
            </Card> */}
            <Card className="px-4  pb-4">
              <p className="font-semibold text-xl">Artificial Intelligence</p>
              <p>
                Cutting-edge AI development services ensuring that businesses can leverage the
                advanced AI solutions
              </p>
              <p className="font-semibold mt-2 text-primary">Technologies</p>
              <p>AI/ML, data science, Fraud detection, recommendation system, sales forecast </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    {/* Obstacles from fullscale */}
    {/* <section>
      <h3>The obstacles tech founders and CTOs face</h3>
    </section> */}

    {/* <section id="partners">
      <h3>Trusted by enterprises and startups.</h3>
    </section> */}

    <SplitSection
      id="insights"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">We build with you</h3>
          <h6 className="mt-2 text-xl font-semibold leading-tight text-primary">
            Hire a dedicated team to design, build, and scale your vision
          </h6>
          <p className="mt-4 text-xl font-light leading-relaxed">
            We provide pool of talented engineers & managers needed to deliver as per client's
            product roadmap. According to quality, experience and market trend,we provide
            cost-effective solution with our team of experts.
          </p>
        </div>
      }
      secondarySlot={<BoardImage />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">We build for you</h3>
          <h6 className="mt-2 text-xl font-semibold leading-tight text-primary">
            Build a new product with new technology
          </h6>
          <p className="mt-4 text-xl font-light leading-relaxed">
            Whether client wants us to build long term product or MVP, we provide END to END
            development. We guide and deliver across your product lifecycle, from idea to MVP to
            full fledged enterprise application.
          </p>
        </div>
      }
      secondarySlot={<HelpImage />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Products</h3>
          <div className="mt-8 text-xl font-light leading-relaxed">
            <div>Data center design & architect</div>
            <div>Security Operations Center </div>
            <div>Cybersecurity</div>
            {/* <div>Cybersecurity Sandbox lab</div> */}
            <div>Data science as a Service</div>
            <div>E-voucher and Merchant Suite</div>
            <div>Transactional Banking with QR</div>
          </div>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <section id="stats" className="py-20 lg:pt-5">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Quality" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Delivery in Time" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Cost Effective" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-10 lg:py-10">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map((customer) => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container mx-auto my-5 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-4xl font-semibold">Ready to grow your business?</h3>
      <p className="mt-8 text-xl font-light px-8">
        As an outcome-focused and result-conscious organization that offers a complete portfolio of
        custom software development, we provide brilliant software engineering and consulting to our
        clients globally at compelling economic value.
      </p>
    </section>
  </Layout>
);

export default Index;
